import { AGGREGATE_FUNCTIONS, CrudActions, FORM_TRIGGER_TYPES, SidebarContainer } from "utils/constants";
import { TableFilterType, TableColumnType, Page, Action } from "./baTypes";

export type NestedViewRecordProps =
  | {
      isNestedView?: boolean;
      queryParams?: undefined;
    }
  | {
      isNestedView?: boolean;
      queryParams: {
        tabId: string;
        tabPath: string;
        nestedRecordId: string;
      }[];
    }
  | undefined;

export type RecordItem = Record<string, any>;
export type SelectOption = {
  title: string;
  value: any;
  record?: RecordItem;
  optionData?: RecordItem;
  column?: TableColumnType;
} & RecordItem;

export type SelectOptions = SelectOption[];
export type SelectValue = SelectOption | SelectOptions | null;

export type AddressValue = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
};

export type AddressComplete = {
  id: string;
  longitude?: number;
  latitude?: number;
} & AddressValue;

export type RouteError = { error: Error; reset: () => void };
export type FileUploadInput = {
  file: File;
  path: string;
  name: string;
} & RecordItem;

export type FileUploadResults = {
  uploadCount: number;
  uploadedFiles: FileUploadInput[];
  errorFileData: FileUploadInput[];
};

export interface GridViewColumn extends TableColumnType {
  renderHeader?: (...args: any[]) => JSX.Element;
  disableSorting?: boolean; // this can be moved to TableColumnType if we plan to store in DB later
  cell?: (...args: any[]) => JSX.Element;
}

export type TableColumnChangeProps = {
  id: string;
  newProps: Partial<TableColumnType> | Partial<TableFilterType>;
  callSave?: boolean;
  addLookupLabelsCheck?: boolean;
};

export type NavigationItemAction = {
  id: string;
  name: string;
  type: string;
  properties: any;
  icon?: string;
  waitForSuccess?: boolean;
  tabPageId?: string; // Added in DetailTabView to be used in webhook requests
  tableName?: string; // Added in DetailTabView to be used in webhook requests
  onClick?: () => void; // Added in menu actions to rename action
  hasDivider?: boolean;
  ui_action_id?: Action;
};

export type MenuItemAdditionalConfig = {
  showAsCreateForm?: boolean;
  createFormViewId?: string;
  displayCreateFormAsModal?: boolean;
  defaultOpen?: boolean; // used to store default state for folder/section
};

export type NavigationItem = {
  id?: string;
  name: string;
  href: string | { pathname: string; query: { [key: string]: string | string[] } };
  children?: NavigationItem[];
  actions?: NavigationItemAction[];
  is_divider?: boolean;
  icon?: string;
  ui_page_id?: Page;
  is_section?: boolean;
  is_folder?: boolean;
  onClick?: () => void;
  isActive?: boolean;
  open_in_new_tab?: boolean;
  onMouseDown?: () => void;
  tabPageId?: string;
  isAdminMenuItem?: boolean;
  show_admin_only?: boolean;
  show_record_count?: boolean;
  additionalConfig?: MenuItemAdditionalConfig;
  tableName?: string;
  ui_menu_id?: string;
  isViewOnlyMenu?: boolean; // Used when defined the menu in client side
  sort_order?: string;
  parent_id?: string;
  showExposedActions?: boolean;
};

export type QueryHookOptions = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  enabled?: boolean;
  refetchInterval?: false | number;
  staleTime?: number;
  gcTime?: number;
  refetchOnReconnect?: boolean;
};

export type CardData = {
  row: RecordItem;
  cells: JSX.Element[];
  featuredImage?: JSX.Element | null;
  selected?: boolean;
  header?: RecordItem;
  formattedRecordVal?: RecordItem;
};

export enum ActionHandlerDisplayTypes {
  CELL = "cell"
}

export type BulkActionState = {
  selectedRows?: RecordItem[];
  isSelected?: boolean;
  isModalOpen?: boolean;
  bulkAction?: "edit" | "delete";
  tablePageSlug?: string;
  tableName?: string;
  uploadedFilesSelection?: RecordItem[];
};

export type PlayerMeta = {
  title: string;
  audio: {
    src: string;
    type: string;
  };
};

export type PlayerState = {
  playing: boolean;
  muted: boolean;
  duration: number;
  currentTime: number;
  meta: PlayerMeta;
};

export type PlayerActions = {
  play: (data?: PlayerMeta) => void;
  pause: () => void;
  toggle: (data?: PlayerMeta) => void;
  toggleMute: () => void;
  isPlaying: (data?: PlayerMeta) => boolean;
  seek: (time: any[]) => void;
  seekBy: (time: number) => void;
  playbackRate: (rate: number) => void;
  close: () => void;
  getPlayerReady: (data?: PlayerMeta) => void;
};

export type Player = PlayerState & PlayerActions;
export type SearchTableColumnChangeProps = {
  id: string;
  newProps: Partial<TableColumnType>;
};

export enum COLUMN_CONFIG_ERROR_TYPES {
  INVALID_LOOKUP_PATH = "Invalid_Lookup_Path",
  INVALID_LOOKUP_FILTERS = "Invalid_Lookup_Filters",
  INVALID_COLUMN_OPTIONS = "Invalid_Column_Options",
  INVALID_COLUMN_FILTERS = "Invalid_Column_Filters",
  INVALID_INLINE_CREATE = "Invalid_Inline_Create",
  INVALID_ROLLUP_SOURCE = "Invalid_Rollup_Source",
  INVALID_FORMULA = "Invalid_Formula",
  INVALID_COLUMN = "Invalid_Column",
  INVALID_JOIN_TABLE_FK_REF = "Join_Table_FK_Ref",
  INVALID_LOOKUP_COLUMNS = "Invalid_Lookup_Columns",
  INVALID_RECORD_TYPE_LOOKUP = "Invalid_Record_Type_Lookup",
  INVALID_RECORD_TYPE_COLUMN_OPTIONS = "Invalid_Record_Type_ColOptions"
}

export enum PAGE_ERROR_TYPES {
  INVALID_PAGE_CONFIG = "Invalid_Page_Config",
  INVALID_PAGE_TABLE = "Invalid_Page_Table",
  INVALID_PAGE_VIEW = "Invalid_Page_View"
}

export const PAGE_TYPES = {
  REPORTING: "reporting",
  DATABASE: "database",
  TEMPLATE: "template",
  STATIC: "static"
};

export enum TILE_ARRAY_ITEM_TYPE {
  OBJECT = "object",
  STRING = "string",
  NUMBER = "number"
}

export type FeaturedImageDetails = {
  value?: string;
  type?: string;
  id?: string;
};

export enum CardSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extra large"
}

export enum DETAIL_TAB_VISIBILITY {
  SIDEBAR = "sidebar",
  DETAIL_TAB = "detail_tab",
  ALL = "all"
}

export enum CONFIG_MODAL_TYPES {
  BASIC_PAGE = "basic_page",
  COLUMN_CONFIG = "column_config",
  PAGE_VIEW = "page_view",
  RECORD_TYPE = "record_type",
  FORM_VIEW = "form_view"
}

export type LookupAvailableTables = {
  "0"?: Array<{ tableName: string; tableType: string; fkeyColName?: string }>;
  "1"?: Array<{ tableName: string; tableType: string; fkeyColName?: string }>;
};

export type SIDEBAR_PARAMS_SET = {
  pageId: string;
  recordId: string;
  fileColId?: string;
};

export type FORM_PARAMS_SET = {
  title?: string;
  formId?: string;
  pageFormId?: string;
  sidebar?: SidebarContainer;
  tablePath?: string;
  action?: CrudActions;
  parentRecordId?: string;
  parentRecordSlug?: string;
  add?: {
    expanded?: boolean;
    addPageId: string;
    tabId?: string;
  };
  trigger?: FORM_TRIGGER_TYPES;
};

export type COLLAPSIBLE_VIEWER_PARAMS_SET = {
  removeViewerId?: string; // used to remove viewer item from collapsible Viewer
};

export type FILTERS_PARAMS_SET = {
  column: TableColumnType;
  filterOperator: string;
  filterValue: any;
  filterSlug: string;
  filterOrGroup?: FILTERS_PARAMS_SET[];
  filterGroupType?: string; // Used to identify Current User / Me filter
};

export type CELL_SIDE_PARAMS_SET = {
  pageId: string;
  recordId: string;
  tabId?: string;
  fileColId?: string;
  type?: SidebarContainer;
  title?: string;
};

export type PAGE_QUERY_ADDITIONAL_PROP_TYPES = Array<
  "views" | "tabs" | "columns" | "column_rules" | "columns_lite" | "views_lite" | "pageFilters" | "actions" | "sections"
>;

export type TableActionsContextSearchState = {
  searchValue: string;
  resultIds: string[];
  totalCount?: number;
  currentPage?: number;
};

export type ActiveSearchContextState = {
  id: string;
  title: string;
  tableName: string;
  recordId?: string;
  isEntityType?: boolean; // When we are filtering by entityType on master index
};

export type InfoLoggerProps = { source: string; additionalInfo?: RecordItem };
